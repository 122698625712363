import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { DepoimentosComponent } from './pages/depoimentos/depoimentos.component';
import { DepoimentosCriarComponent } from './pages/depoimentos-criar/depoimentos-criar.component';
import { DepoimentosEditarComponent } from './pages/depoimentos-editar/depoimentos-editar.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { ServicosCriarComponent } from './pages/servicos-criar/servicos-criar.component';
import { ServicosEditarComponent } from './pages/servicos-editar/servicos-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { ClientesCriarComponent } from './pages/clientes-criar/clientes-criar.component';
import { ClientesEditarComponent } from './pages/clientes-editar/clientes-editar.component';
import { DiferenciaisComponent } from './pages/diferenciais/diferenciais.component';
import { DiferenciaisCriarComponent } from './pages/diferenciais-criar/diferenciais-criar.component';
import { DiferenciaisEditarComponent } from './pages/diferenciais-editar/diferenciais-editar.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { AgendaCriarComponent } from './pages/agenda-criar/agenda-criar.component';
import { AgendaEditarComponent } from './pages/agenda-editar/agenda-editar.component';
import { FiltrosAgendaComponent } from './pages/filtros-agenda/filtros-agenda.component';
import { FiltrosAgendaCriarComponent } from './pages/filtros-agenda-criar/filtros-agenda-criar.component';
import { FiltrosAgendaEditarComponent } from './pages/filtros-agenda-editar/filtros-agenda-editar.component';
import { PermicoesDocumentosComponent } from './pages/permicoes-documentos/permicoes-documentos.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { DocumentosEditarComponent } from './pages/documentos-editar/documentos-editar.component';
import { DocumentosClientesComponent } from './pages/documentos-clientes/documentos-clientes.component';
import { DocumentosClientesEditarComponent } from './pages/documentos-clientes-editar/documentos-clientes-editar.component';
import { IsClientLoggedInGuard } from './is-client-logged-in.guard';
import { IsAdminLoggedInGuard } from './is-admin-logged-in.guard';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'contato', component: ContatoComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'sobre', component: SobreComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'documentos', component: DocumentosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'documentos/editar', component: DocumentosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'c/documentos', component: DocumentosClientesComponent, canActivate: [IsClientLoggedInGuard] },
  { path: 'c/documentos/editar', component: DocumentosClientesEditarComponent, canActivate: [IsClientLoggedInGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios/adicionar', component: UsuariosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'usuarios/editar/:id', component: UsuariosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners', component: BannersComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners/adicionar', component: BannersCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'banners/editar/:id', component: BannersEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'noticias', component: NoticiasComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'noticias/adicionar', component: NoticiasCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'noticias/editar/:id', component: NoticiasEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'depoimentos', component: DepoimentosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'depoimentos/adicionar', component: DepoimentosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'depoimentos/editar/:id', component: DepoimentosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'servicos', component: ServicosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'servicos/adicionar', component: ServicosCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'servicos/editar/:id', component: ServicosEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'clientes', component: ClientesComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'clientes/adicionar', component: ClientesCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'clientes/editar/:id', component: ClientesEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'agenda', component: AgendaComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'agenda/adicionar', component: AgendaCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'agenda/editar/:id', component: AgendaEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'filtros-agenda', component: FiltrosAgendaComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'filtros-agenda/adicionar', component: FiltrosAgendaCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'filtros-agenda/editar/:id', component: FiltrosAgendaEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'diferenciais', component: DiferenciaisComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'diferenciais/adicionar', component: DiferenciaisCriarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'diferenciais/editar/:id', component: DiferenciaisEditarComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: 'permicoes-documentos', component: PermicoesDocumentosComponent, canActivate: [IsAdminLoggedInGuard] },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
