import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { FiltroAgenda } from 'src/app/models/filtro-agenda.model';

@Injectable({
  providedIn: 'root'
})
export class FiltroAgendaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<FiltrosAgendaApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/filtroAgenda/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/filtroAgenda/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/filtroAgenda/buscar?id=${id}`);
  }

  post(filtroAgenda: FiltroAgenda): Observable<any> {
    return this.api.post('/filtroAgenda/inserir', filtroAgenda);
  }

  patch(filtroAgenda: FiltroAgenda): Observable<any> {
    return this.api.post('/filtroAgenda/alterar', filtroAgenda);
  }

  delete(filtroAgenda: FiltroAgenda): Observable<any> {
    return this.api.post('/filtroAgenda/deletar', filtroAgenda);
  }

  deleteSelected(filtrosAgenda: FiltroAgenda[]): Observable<any> {
    return this.api.post('/filtroAgenda/deletarLista', filtrosAgenda);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(op1: FiltroAgenda, op2: FiltroAgenda) {
    return op1 && op2 ? op1.id === op2.id : op1 === op2;
  }

}

export interface FiltrosAgendaApi {
  filtrosAgenda: FiltroAgenda[];
  numeroPaginas: number;
}

