import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentoService } from 'src/app/services/class/documento.service';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { Documento } from 'src/app/models/documento.model';
import { HelperService } from 'src/app/services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { Cliente } from 'src/app/models/cliente.model';
import { ClienteService } from 'src/app/services/class/cliente.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-documentos-editar',
  templateUrl: './documentos-editar.component.html',
  styleUrls: ['./documentos-editar.component.scss']
})
export class DocumentosEditarComponent implements OnInit {

  data: any[] = [];
  buscarSubscription: Subscription;

  progress: number = 0;

  cliente: Cliente = new Cliente();
  buscarClienteSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public documentoService: DocumentoService,
    public clienteService: ClienteService,
    public loadingService: LoadingService,
    public helper: HelperService,
    public auth: AuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(query => {
      this.buscarCliente(query.idCliente);
      this.buscar(query.idGrupoTipoDoc, query.mesAno, query.idCliente);
    });
  }

  buscar(idGrupoTipoDoc: number, mesAno: string, idCliente: number) {
    this.loadingService.present('Buscando...');
    this.buscarSubscription = this.documentoService.getTipoDocumento(idGrupoTipoDoc, mesAno, idCliente)
      .subscribe((res: any) => {
        this.data = res.map(obj => {
          const files: File[] = [];
          Object.assign(obj, { files: files });
          return obj;
        });
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
  }

  buscarCliente(id: number) {
    this.buscarSubscription = this.clienteService.getById(id)
      .subscribe((res: Cliente) => this.cliente = res);
  }

  deletarImagem(documento: Documento, parentIndex: number) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Deletar arquivo',
        description: 'Você realmente quer deletar esse item? Esse processo não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Deletando documento...');
        this.documentoService.delete(documento).subscribe(res => {
          const i = this.data[parentIndex].documentos.findIndex((doc: Documento) => doc.id === documento.id);
          this.data[parentIndex].documentos.splice(i, 1);
          this.loadingService.dismiss()
        }, e => this.loadingService.dismiss())
      }
    })
  }

  onSelect(item: any, event) {

    item.files.push(...event.addedFiles);
    let count = event.addedFiles.length;
    const query = this.route.snapshot.queryParams;

    this.loadingService.present('Inserindo');

    event.addedFiles.forEach(file => {
      const url = `/documento/inserirArquivo?mesAno=${query.mesAno}&idCliente=${query.idCliente}&idTipoDocumento=${item.id}`;
      this.documentoService.postFile(file, url, 'arquivo')
        .subscribe((e: HttpProgressEvent | any) => {
          if (e.type === 4) {
            count--;
            if (count == 0) {
              this.progress = 0;
              this.loadingService.dismiss();
              item.files = [];
              this.buscar(query.idGrupoTipoDoc, query.mesAno, query.idCliente);
            }
          } else {
            this.progress = Math.round((e.loaded / e.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        })
    });
  }

  onRemove(item: any, event) {
    item.files.splice(item.files.indexOf(event), 1);
  }
}
