import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Sobre } from 'src/app/models/sobre.model';

@Injectable({
  providedIn: 'root'
})
export class SobreService {

  constructor(
    public api: ApiService
  ) { }

  get(): Observable<any> {
    return this.api.get(`/sobre/buscar`);
  }

  patch(sobre: Sobre): Observable<any> {
    return this.api.post('/sobre/alterar', sobre);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
