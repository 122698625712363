import { NgModule } from '@angular/core';
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTreeModule } from '@angular/material/tree';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTabsModule } from '@angular/material/tabs';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// components
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalResetPasswordComponent } from './components/modal-reset-password/modal-reset-password.component';
// locale
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { DepoimentosComponent } from './pages/depoimentos/depoimentos.component';
import { DepoimentosCriarComponent } from './pages/depoimentos-criar/depoimentos-criar.component';
import { DepoimentosEditarComponent } from './pages/depoimentos-editar/depoimentos-editar.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { ServicosCriarComponent } from './pages/servicos-criar/servicos-criar.component';
import { ServicosEditarComponent } from './pages/servicos-editar/servicos-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { ClientesCriarComponent } from './pages/clientes-criar/clientes-criar.component';
import { ClientesEditarComponent } from './pages/clientes-editar/clientes-editar.component';
import { DiferenciaisComponent } from './pages/diferenciais/diferenciais.component';
import { DiferenciaisCriarComponent } from './pages/diferenciais-criar/diferenciais-criar.component';
import { DiferenciaisEditarComponent } from './pages/diferenciais-editar/diferenciais-editar.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { AgendaCriarComponent } from './pages/agenda-criar/agenda-criar.component';
import { AgendaEditarComponent } from './pages/agenda-editar/agenda-editar.component';
import { FiltrosAgendaComponent } from './pages/filtros-agenda/filtros-agenda.component';
import { FiltrosAgendaCriarComponent } from './pages/filtros-agenda-criar/filtros-agenda-criar.component';
import { FiltrosAgendaEditarComponent } from './pages/filtros-agenda-editar/filtros-agenda-editar.component';
import { PermicoesDocumentosComponent } from './pages/permicoes-documentos/permicoes-documentos.component';
import { ModalParentEditComponent } from './components/modal-parent-edit/modal-parent-edit.component';
import { ModalChildEditComponent } from './components/modal-child-edit/modal-child-edit.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { DocumentosEditarComponent } from './pages/documentos-editar/documentos-editar.component';
import { DocumentosCriarComponent } from './pages/documentos-criar/documentos-criar.component';
import { FilterPipe } from './filter.pipe';
import { DocumentosClientesComponent } from './pages/documentos-clientes/documentos-clientes.component';
import { DocumentosClientesEditarComponent } from './pages/documentos-clientes-editar/documentos-clientes-editar.component';

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalResetPasswordComponent,
    DialogComponent,
    LoadingComponent,
    // pages
    HomeComponent,
    LoginComponent,
    UsuariosComponent,
    UsuariosCriarComponent,
    UsuariosEditarComponent,
    ConfiguracoesComponent,
    ContatoComponent,
    BannersComponent,
    BannersCriarComponent,
    BannersEditarComponent,
    NoticiasComponent,
    NoticiasEditarComponent,
    NoticiasCriarComponent,
    DepoimentosComponent,
    DepoimentosCriarComponent,
    DepoimentosEditarComponent,
    ServicosComponent,
    ServicosCriarComponent,
    ServicosEditarComponent,
    SobreComponent,
    ClientesComponent,
    ClientesCriarComponent,
    ClientesEditarComponent,
    DiferenciaisComponent,
    DiferenciaisCriarComponent,
    DiferenciaisEditarComponent,
    AgendaComponent,
    AgendaCriarComponent,
    AgendaEditarComponent,
    FiltrosAgendaComponent,
    FiltrosAgendaCriarComponent,
    FiltrosAgendaEditarComponent,
    PermicoesDocumentosComponent,
    ModalParentEditComponent,
    ModalChildEditComponent,
    DocumentosComponent,
    DocumentosEditarComponent,
    DocumentosCriarComponent,
    // pipe
    FilterPipe,
    DocumentosClientesComponent,
    DocumentosClientesEditarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CKEditorModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSummernoteModule,
    NgxPhoneMaskBrModule,
    NgxMatSelectSearchModule,
    // Material
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTreeModule,
    NgxDropzoneModule,
  ],
  entryComponents: [
    DialogComponent,
    ModalResetPasswordComponent,
    ModalParentEditComponent,
    ModalChildEditComponent,
    DocumentosCriarComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
