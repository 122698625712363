import { Agenda } from 'src/app/models/agenda.model';
import { AgendaService } from 'src/app/services/class/agenda.service';
// default
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { FiltroAgenda } from 'src/app/models/filtro-agenda.model';
import { Subscription } from 'rxjs';
import { FiltroAgendaService, FiltrosAgendaApi } from 'src/app/services/class/filtro-agenda.service';

@Component({
  selector: 'app-agenda-criar',
  templateUrl: './agenda-criar.component.html',
  styleUrls: ['./agenda-criar.component.scss']
})
export class AgendaCriarComponent implements OnInit {

  data: Agenda = new Agenda();
  filtrosAgenda: FiltroAgenda[] = [];

  buscarFiltrosAgendaSubscription: Subscription;

  constructor(
    public agendaService: AgendaService,
    public filtroAgendaService: FiltroAgendaService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() { 
    this.buscarFiltrosAgenda();
  }

  ngOnDestroy() {
    this.buscarFiltrosAgendaSubscription.unsubscribe();
  }

  buscarFiltrosAgenda() {
    this.buscarFiltrosAgendaSubscription = this.filtroAgendaService.get(-99, -99, '1', 'ASC')
      .subscribe((res: FiltrosAgendaApi) => this.filtrosAgenda = res.filtrosAgenda);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.agendaService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/agenda']).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

}
