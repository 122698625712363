import { Sobre } from 'src/app/models/sobre.model';
// Default
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SobreService } from 'src/app/services/class/sobre.service';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  data: Sobre = new Sobre();
  buscarSubscription: Subscription;

  file: File;
  progress: number = 0;

  constructor(
    public sobreService: SobreService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  ngOnDestroy() {
  }

  buscar(id: number) {
    this.buscarSubscription = this.sobreService.get()
      .subscribe((res: Sobre) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');

    this.sobreService.patch(this.data)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/sobre/imagem?id=${res}`);
        this.helper.openSnackBar('Item alterado com sucesso.');
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/sobre/imagem?id=-99').then((res: any) => {
      this.data.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.sobreService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }


}
