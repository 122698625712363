import { GrupoTipoDocumento } from './grupo-tipo-documento.model';

export class Cliente {

    id: any;
    imagem: string = '';
    nome: string = '';
    link: string = '';
    situacao: string = 'A';
    enviaEmail: string = 'N';
    linkEmail: string = '';
    grupoTipoDoc: GrupoTipoDocumento = new GrupoTipoDocumento();
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}