import { Diferencial } from 'src/app/models/diferencial.model';
import { DiferencialService } from 'src/app/services/class/diferencial.service';
// default
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material';

@Component({
  selector: 'app-diferenciais-criar',
  templateUrl: './diferenciais-criar.component.html',
  styleUrls: ['./diferenciais-criar.component.scss']
})
export class DiferenciaisCriarComponent implements OnInit {

  data: Diferencial = new Diferencial();

  constructor(
    public diferencialService: DiferencialService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.diferencialService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/diferenciais']).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }
}
