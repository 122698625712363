import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { GrupoTipoDocumento } from 'src/app/models/grupo-tipo-documento.model';

@Injectable({
  providedIn: 'root'
})
export class GrupoTipoDocumentoService {

  constructor(
    public api: ApiService
  ) { }

  get(): Observable<any> {
    return this.api.get(`/grupoTipoDocumento/buscar`);
  }

  getSelect(): Observable<any> {
    return this.api.get(`/select/grupoTipoDocumento/buscar`);
  }

  getById(id: number): Observable<any> {
    return this.api.get(`/grupoTipoDocumento/buscar?id=${id}`);
  }
  
  post(grupoTipoDocumento: GrupoTipoDocumento): Observable<any> {
    return this.api.post('/grupoTipoDocumento/inserir', grupoTipoDocumento);
  }

  patch(grupoTipoDocumento: GrupoTipoDocumento): Observable<any> {
    return this.api.post('/grupoTipoDocumento/alterar', grupoTipoDocumento);
  }

  delete(grupoTipoDocumento: GrupoTipoDocumento): Observable<any> {
    return this.api.post('/grupoTipoDocumento/deletar', grupoTipoDocumento);
  }

  compareFn(v1: GrupoTipoDocumento, v2: GrupoTipoDocumento): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}
