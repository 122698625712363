import { TipoDocumento } from './tipo-documento.model';

export class GrupoTipoDocumento {

    id: number;
    nome: string = '';
    observacao: string = '';
    situacao: string = 'A';
    tiposDocumento: TipoDocumento[] = [];
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}