import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleChange } from '@angular/material';
import { NgForm } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { TipoDocumento } from 'src/app/models/tipo-documento.model';
import { LoadingService } from 'src/app/services/loading.service';
import { TipoDocumentoService } from 'src/app/services/class/tipo-documento.service';
import { GrupoTipoDocumento } from 'src/app/models/grupo-tipo-documento.model';
import { GrupoTipoDocumentoService } from 'src/app/services/class/grupo-tipo-documento.service';

@Component({
  selector: 'app-modal-parent-edit',
  templateUrl: './modal-parent-edit.component.html',
  styleUrls: ['./modal-parent-edit.component.scss']
})
export class ModalParentEditComponent implements OnInit {

  grupoTipoDocumento: GrupoTipoDocumento = new GrupoTipoDocumento();

  constructor(
    public dialogRef: MatDialogRef<ModalParentEditComponent>,
    public helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: GrupoTipoDocumento,
    public loadingService: LoadingService,
    public grupoTipoDocumentoService: GrupoTipoDocumentoService
  ) { }

  ngOnInit() {
    this.loadingService.present('Buscando...');
    this.grupoTipoDocumento = this.data;
    setTimeout(() => {
      this.buscarTipoProduto(this.data.id);
    }, 280)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos');
      return;
    }

    this.dialogRef.close(this.grupoTipoDocumento);
  }

  buscarTipoProduto(id: number) {
    this.grupoTipoDocumentoService.getById(id).subscribe((res: GrupoTipoDocumento) => {
      this.grupoTipoDocumento.nome = res.nome;
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.grupoTipoDocumento.situacao = 'A' : this.grupoTipoDocumento.situacao = 'I';
  }

}
