import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Diferencial } from 'src/app/models/diferencial.model';

@Injectable({
  providedIn: 'root'
})
export class DiferencialService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<DiferenciaisApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/diferencial/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/diferencial/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/diferencial/buscar?id=${id}`);
  }

  post(diferencial: Diferencial): Observable<any> {
    return this.api.post('/diferencial/inserir', diferencial);
  }

  patch(diferencial: Diferencial): Observable<any> {
    return this.api.post('/diferencial/alterar', diferencial);
  }

  delete(diferencial: Diferencial): Observable<any> {
    return this.api.post('/diferencial/deletar', diferencial);
  }

  deleteSelected(diferenciais: Diferencial[]): Observable<any> {
    return this.api.post('/diferencial/deletarLista', diferenciais);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface DiferenciaisApi {
  diferenciais: Diferencial[];
  numeroPaginas: number;
}
