import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], search: string, param: string): any {
    if (!items) return [];
    if (!search) return items;
    search = search.toLowerCase();
    return items.filter(it => it[param].toLowerCase().includes(search));
  }

}
