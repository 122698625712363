export class Depoimento {

    id: any;
    nome: string = '';
    cargo: string = '';
    empresa: string = '';
    descricao: string = '';
    imagem: string = '';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}