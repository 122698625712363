import { FiltroAgenda } from './filtro-agenda.model';

export class Agenda {

    id: number;
    data: Date = new Date();
    nome: string = '';
    descricao: string = '';
    link: string = '';
    filtro: FiltroAgenda = new FiltroAgenda();
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}