import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfiguracaoService } from './../../services/class/configuracao.service';
import { CadastroGeral } from './../../models/cadastro-geral.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.scss']
})
export class ConfiguracoesComponent implements OnInit {

  cadastroGeral: CadastroGeral = new CadastroGeral();
  cadastroGeralSubscription: Subscription;

  constructor(
    public configuracaoService: ConfiguracaoService,
    public helper: HelperService,
    public loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.cadastroGeralSubscription = this.configuracaoService.get()
      .subscribe((res: CadastroGeral) => {
        this.cadastroGeral = res;
      })
  }

  ngOnDestroy() {
    this.cadastroGeralSubscription.unsubscribe();
  }

  alterarCadastroGeral(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando cadastro geral...');

    this.configuracaoService.patch(this.cadastroGeral)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Cadastro geral alterado com sucesso.');
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
  }

}
