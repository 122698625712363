export class Servico {

    id: any;
    imagem: string = '';
    nome: string = '';
    descricaoSimples: string = '';
    descricaoCompleta: string = '';
    slug: string = '';
    background: string = '';
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}