import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Documento } from 'src/app/models/documento.model';
import { ClientesApi } from './cliente.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(
    public api: ApiService,
    public auth: AuthService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<ClientesApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/documento/cliente/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/documento/cliente/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getMesAno(idCliente: number, numeroPagina: number, registroPorPagina: number, filtro: string = '%'): Observable<MesesAnoApi> {
    return this.api.get(`/documento/mesAno/buscar?idCliente=${idCliente}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&filtro=${filtro}`)
  }

  getTipoDocumento(idGrupoTipoDoc: number, mesAno: string, idCliente: number, atualizaVisualizado: boolean = false) {
    const tipoUsuario = this.auth.user.tipo;
    return this.api.get(`/documento/tipoDocumento/buscar?idGrupoTipoDoc=${idGrupoTipoDoc}&mesAno=${mesAno}&idCliente=${idCliente}&atualizaVisualizado=${tipoUsuario === 'A' ? atualizaVisualizado : true}`);
  }

  post(mesAno: number, idCliente: number, idTipoDocumento: number) {
    return this.api.post(`/ documento / inserirArquivo ? mesAno = ${mesAno}& idCliente=${idCliente}& idTipoDocumento=${idTipoDocumento} `);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  delete(documento: Documento) {
    return this.api.post(`/documento/deletar`, documento);
  }

  alterarBaixado(id: number) {
    return this.api.get(`/documento/alterarBaixado?id=${id}`)
  }
}

export interface DocumentosApi {
  documentos: Documento[];
  numeroPaginas: number;
}

export interface MesesAnoApi {
  mesesAno: any[];
  numeroPaginas: number;
}
