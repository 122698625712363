import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentoService } from 'src/app/services/class/documento.service';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { HelperService } from 'src/app/services/helper.service';
import { Cliente } from 'src/app/models/cliente.model';
import { ClienteService } from 'src/app/services/class/cliente.service';
import { AuthService } from 'src/app/services/auth.service';
import { Documento } from 'src/app/models/documento.model';

@Component({
  selector: 'app-documentos-clientes-editar',
  templateUrl: './documentos-clientes-editar.component.html',
  styleUrls: ['./documentos-clientes-editar.component.scss']
})
export class DocumentosClientesEditarComponent implements OnInit {

  data: any[] = [];
  buscarSubscription: Subscription;

  progress: number = 0;

  cliente: Cliente = new Cliente();
  buscarClienteSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public documentoService: DocumentoService,
    public clienteService: ClienteService,
    public loadingService: LoadingService,
    public helper: HelperService,
    public router: Router,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(query => {
      this.buscarCliente(query.idCliente);
      this.buscar(query.idGrupoTipoDoc, query.mesAno, query.idCliente);
    });
  }

  buscar(idGrupoTipoDoc: number, mesAno: string, idCliente: number) {
    this.loadingService.present('Buscando...');
    this.buscarSubscription = this.documentoService.getTipoDocumento(idGrupoTipoDoc, mesAno, idCliente)
      .subscribe((res: any) => {
        this.data = res.map(obj => {
          const files: File[] = [];
          Object.assign(obj, { files: files });
          return obj;
        });
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
  }

  buscarCliente(id: number) {
    this.buscarSubscription = this.clienteService.getById(id)
      .subscribe((res: Cliente) => this.cliente = res);
  }

  alterarBaixado(file: Documento) {
    if (this.auth.user.tipo === 'A') {
      window.open(file.caminhoArquivo, '_blank')
      return;
    }
    this.documentoService.alterarBaixado(file.id)
      .subscribe(res => window.open(file.caminhoArquivo, '_blank'));
  }
}
