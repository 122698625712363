import { Cliente } from 'src/app/models/cliente.model';
// default
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { DocumentoService, MesesAnoApi } from 'src/app/services/class/documento.service';
import { Router } from '@angular/router';
import { DocumentosCriarComponent } from '../documentos-criar/documentos-criar.component';
import { TipoDocumento } from 'src/app/models/tipo-documento.model';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DocumentosComponent implements OnInit {

  displayedColumns: string[] = ['imagem', '1', '2'];
  // imagem, nome, cargo, descricao
  expandedDisplayedColumns: string[] = ['1', 'actions'];

  data: Cliente[] = [];
  dataExpanded: any[] = [];
  expandedElement: any;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  searchExpanded: string;
  filterExpandedSubscription: Subscription;

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild('expandedPaginator', { static: false }) expandedPaginator: MatPaginator;

  constructor(
    public global: GlobalService,
    public documentoService: DocumentoService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.buscar();
  }

  buscar() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.documentoService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, '1', 'ASC');
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.clientes;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  buscarMesAno(idCliente: number) {
    merge(this.expandedPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.documentoService.getMesAno(idCliente, this.expandedPaginator.pageIndex + 1, this.expandedPaginator.pageSize);
        }),
        map(data => {
          this.resultsLength = data.numeroPaginas * this.expandedPaginator.pageSize;
          return data.mesesAno;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => this.dataExpanded = data);
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.documentoService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, '1', 'ASC', e.toLocaleLowerCase())
        .subscribe((res: any) => {
          this.data = this.paginator.pageIndex == 0 ? res.clientes : this.data.concat(res.clientes);
        });
    } else {
      this.buscar();
    }
  }

  filterExpanded(e, idCliente: number) {
    if (this.expandedPaginator.pageIndex > 1) {
      this.expandedPaginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterExpandedSubscription && !this.filterExpandedSubscription.closed) {
        this.filterExpandedSubscription.unsubscribe();
      }
      this.filterExpandedSubscription = this.documentoService.getMesAno(idCliente, this.expandedPaginator.pageIndex + 1, this.expandedPaginator.pageSize, e.toLocaleLowerCase())
        .subscribe((res: MesesAnoApi) => {
          this.dataExpanded = this.expandedPaginator.pageIndex == 0 ? res.mesesAno : this.data.concat(res.mesesAno);
        });
    } else {
      this.buscarMesAno(idCliente);
    }
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  clearFilterExpanded(idCliente: number) {
    this.searchExpanded = '';
    this.buscarMesAno(idCliente);
  }

  expandedElementChange(element: Cliente) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.buscarMesAno(element.id);
  }

  editarDocumento(cliente: Cliente, mesAno: string) {
    this.router.navigate(['/documentos/editar'], {
      queryParams: {
        idGrupoTipoDoc: cliente.grupoTipoDoc.id,
        mesAno: mesAno,
        idCliente: cliente.id
      }
    })
  }

  openDocumentoCriar() {
    const dialogRef = this.dialog.open(DocumentosCriarComponent, {
      width: '540px',
    });

    dialogRef.afterClosed().subscribe((result: TipoDocumento) => {
      if (result) {
        console.log(result);
        this.router.navigate(['/documentos/editar'], {
          queryParams: {
            idGrupoTipoDoc: result.idGrupoTipoDoc,
            mesAno: result.mesAno,
            idCliente: result.idCliente,
          }
        })
      }
    })
  }
}
