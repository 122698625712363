import { Component, OnInit, Injectable, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatDialog } from '@angular/material';
import { TipoDocumento } from 'src/app/models/tipo-documento.model';
import { GrupoTipoDocumento } from 'src/app/models/grupo-tipo-documento.model';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TipoDocumentoService } from 'src/app/services/class/tipo-documento.service';
import { GrupoTipoDocumentoService } from 'src/app/services/class/grupo-tipo-documento.service';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { ModalParentEditComponent } from 'src/app/components/modal-parent-edit/modal-parent-edit.component';
import { ModalChildEditComponent } from 'src/app/components/modal-child-edit/modal-child-edit.component';


@Injectable()
export class ConfigService {

  dataChange = new BehaviorSubject<GrupoTipoDocumento[]>([]);

  get data(): GrupoTipoDocumento[] { return this.dataChange.value; }

  constructor(
    private grupoTipoDocumentoService: GrupoTipoDocumentoService,
    private tipoDocumentoService: TipoDocumentoService,
    private loadingService: LoadingService) {
  }

  insertFather(parent) {
    this.loadingService.present('Adicionando...');
    this.grupoTipoDocumentoService.post(parent).subscribe(res => {
      parent.id = res.id;
      this.data.push(parent);
      this.dataChange.next(this.data);
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

  insertItem(parent: GrupoTipoDocumento | any, name: string) {
    if (parent.tiposDocumento) {
      const tipoDocumento = new TipoDocumento();
      tipoDocumento.nome = name;
      tipoDocumento.idGrupoTipoDoc = parent.id;
      parent.tiposDocumento.push(tipoDocumento);
      this.dataChange.next(this.data);
      // this.loadingService.present('Adicionando...');
      // this.tipoDocumentoService.post(tipoDocumento)
      //   .subscribe((res: any) => {
      //     console.log(res);
      //     this.loadingService.dismiss();
      //   }, e => this.loadingService.dismiss());
    }
  }

  popItem(parent: GrupoTipoDocumento) {
    const config = this.data[parent.index];
    if (config.tiposDocumento) {
      config.tiposDocumento.pop();
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: any, name: string) {
    this.loadingService.present('Adicionando...');
    node.nome = name;
    this.tipoDocumentoService.post(node).subscribe(res => {
      console.log(res);
      node.id = res.id;
      this.dataChange.next(this.data);
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

  removeItem(node: TipoDocumento | GrupoTipoDocumento, parent?: TipoDocumento) {
    this.loadingService.present('Removendo...');
    if (parent) {
      const tipoDocumento = this.data[parent.index].tiposDocumento[node.index];
      this.tipoDocumentoService.delete(tipoDocumento).subscribe(res => {
        this.data[parent.index].tiposDocumento.splice(node.index, 1);
        this.dataChange.next(this.data);
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
    } else {
      const grupoTipoDocumento = this.data[node.index];
      this.grupoTipoDocumentoService.delete(grupoTipoDocumento).subscribe(res => {
        this.data.splice(node.index, 1);
        this.dataChange.next(this.data);
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
    }
  }
}

@Component({
  selector: 'app-permicoes-documentos',
  templateUrl: './permicoes-documentos.component.html',
  styleUrls: ['./permicoes-documentos.component.scss'],
  providers: [ConfigService]
})
export class PermicoesDocumentosComponent implements OnInit {

  flatNodeMap = new Map<TipoDocumento, GrupoTipoDocumento>();
  nestedNodeMap = new Map<GrupoTipoDocumento, TipoDocumento>();

  selectedParent: TipoDocumento | null = null;

  buscarSubscription: Subscription;

  treeControl: FlatTreeControl<TipoDocumento>;
  treeFlattener: MatTreeFlattener<GrupoTipoDocumento, TipoDocumento>;
  dataSource: MatTreeFlatDataSource<GrupoTipoDocumento, TipoDocumento>;

  newItemName = '';

  showFather: boolean = false;
  newFatherName = '';

  constructor(
    private grupoTipoDocumentoService: GrupoTipoDocumentoService,
    private tipoDocumentoService: TipoDocumentoService,
    public loadingService: LoadingService,
    public route: ActivatedRoute,
    private _service: ConfigService,
    public dialog: MatDialog,
    public auth: AuthService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TipoDocumento>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    _service.dataChange.subscribe(data => {
      const req = data.map((e, i) => {
        e.index = i;
        e.tiposDocumento.map((v, i) => {
          v.index = i;
          return v;
        })
        return e;
      })
      this.dataSource.data = req;
    });
  }

  // functions
  getLevel = (node: TipoDocumento) => node.level;
  isExpandable = (node: TipoDocumento) => node.expandable;
  getChildren = (node: GrupoTipoDocumento): any[] => node.tiposDocumento;
  hasChild = (_: number, _nodeData: TipoDocumento) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TipoDocumento) => _nodeData.name === '';
  getParentNode(node: any): any | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  // end functions
  transformer = (node: GrupoTipoDocumento, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.nome
      ? existingNode
      : new TipoDocumento();

    flatNode.name = node.nome;
    flatNode.level = level;
    flatNode.index = node.index;
    flatNode.expandable = !!node.tiposDocumento;
    flatNode.hasChildren = !!node.tiposDocumento && node.tiposDocumento.length > 0;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  @ViewChild('itemValue', { static: false }) itemValueInput: ElementRef;

  addNewItem(node: TipoDocumento) {
    this.newItemName = '';
    const parentNode = this.flatNodeMap.get(node);
    this._service.insertItem(parentNode!, '');
    this.treeControl.expand(node);
    setTimeout(() => this.itemValueInput.nativeElement.focus(), 100);
  }

  saveNode(node: TipoDocumento, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    const parentNode = this.getParentNode(node);
    this._service.updateItem(nestedNode!, itemValue);
    this.addNewItem(parentNode)
  }

  removeNode(node: TipoDocumento) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir',
        description: 'Você realmente quer excluir esse item? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const nestedNode = this.flatNodeMap.get(node);
        const parentNode = this.getParentNode(node);
        this._service.removeItem(nestedNode!, parentNode!);
      }
    });

  }

  removeItem(node: TipoDocumento) {
    const parentNode = this.getParentNode(node);
    this._service.popItem(parentNode!);
  }

  // father
  @ViewChild('fatherValue', { static: false }) fatherValueInput: ElementRef;
  addNewFather() {
    this.showFather = true;
    setTimeout(() => this.fatherValueInput.nativeElement.focus(), 100);
  }

  saveFather(name: string) {
    const father = new GrupoTipoDocumento();
    father.nome = name;
    this._service.insertFather(father);
    this.closeFather();
  }

  closeFather() {
    this.newFatherName = '';
    this.showFather = false;
  }
  // end father

  ngOnInit() {
    this.buscarConfiguracaoProduto();
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscarConfiguracaoProduto() {
    this.loadingService.present('Buscando configurações...');
    this.buscarSubscription = this.grupoTipoDocumentoService.get().subscribe((res: GrupoTipoDocumento[]) => {
      this._service.dataChange.next(res);
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

  openDialogFather(node: any): void {
    const grupoTipoDocumento = this.flatNodeMap.get(node);
    const dialogRef = this.dialog.open(ModalParentEditComponent, {
      width: '540px',
      data: grupoTipoDocumento
    });

    dialogRef.afterClosed().subscribe((result: GrupoTipoDocumento) => {
      if (result) {
        this.dataSource.data[node.index].nome = result.nome;
        this.loadingService.present('Editando...');
        this.grupoTipoDocumentoService.patch(result).subscribe(res => {
          this._service.dataChange.next(this.dataSource.data);
          this.loadingService.dismiss();
        }, e => this.loadingService.dismiss());
      }
    });
  }

  openDialogChild(node: any): void {
    const tipoDocumento = this.flatNodeMap.get(node);
    const parent = this.getParentNode(node);

    const dialogRef = this.dialog.open(ModalChildEditComponent, {
      width: '540px',
      data: tipoDocumento
    });

    dialogRef.afterClosed().subscribe((result: TipoDocumento) => {
      if (result) {
        this.dataSource.data[parent.index].tiposDocumento[node.index].nome = result.nome;
        this.loadingService.present('Editando...');
        this.tipoDocumentoService.patch(result).subscribe(res => {
          this._service.dataChange.next(this.dataSource.data);
          this.loadingService.dismiss();
        }, e => this.loadingService.dismiss());
      }
    });
  }
}
