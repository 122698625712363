import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { TipoDocumento } from 'src/app/models/tipo-documento.model';

@Injectable({
  providedIn: 'root'
})
export class TipoDocumentoService {

  constructor(
    public api: ApiService
  ) { }

  getById(id: number): Observable<any> {
    return this.api.get(`/tipoDocumento/buscar?id=${id}`);
  }
  
  post(tipoDocumento: TipoDocumento): Observable<any> {
    return this.api.post('/tipoDocumento/inserir', tipoDocumento);
  }

  patch(tipoDocumento: TipoDocumento): Observable<any> {
    return this.api.post('/tipoDocumento/alterar', tipoDocumento);
  }

  delete(tipoDocumento: TipoDocumento): Observable<any> {
    return this.api.post('/tipoDocumento/deletar', tipoDocumento);
  }
}
