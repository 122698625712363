export class Noticia {

    id: any;
    imagemPequena: string = '';
    imagemGrande: string = '';
    autor: string = '';
    data: Date = new Date();
    titulo: string = '';
    descricao: string = '';
    slug: string = '';
    tag: string = '';
    descricaoSimples: string = '';
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}