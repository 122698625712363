import { Usuario } from './../../models/usuario.model';
import { UsuarioService } from './../../services/class/usuario.service';
// Default
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Cliente } from 'src/app/models/cliente.model';
import { Subscription } from 'rxjs';
import { ClienteService } from 'src/app/services/class/cliente.service';

@Component({
  selector: 'app-usuarios-criar',
  templateUrl: './usuarios-criar.component.html',
  styleUrls: ['./usuarios-criar.component.scss']
})
export class UsuariosCriarComponent implements OnInit {

  data: Usuario = new Usuario();
  hide: boolean = true;

  searchClientes: string = '';
  clientes: Cliente[] = [];
  buscarClientesSubscription: Subscription;

  constructor(
    public usuarioService: UsuarioService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public clienteService: ClienteService,
    public router: Router
  ) { }

  ngOnInit() {
    this.buscarClientes();
  }

  ngOnDestroy() {
    this.buscarClientesSubscription.unsubscribe();
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    if (this.data.senha !== this.data.re_senha) {
      this.helper.openSnackBar('As senhas precisam ser iguais.');
      return;
    }

    this.loadingService.present('Inserindo usuário...');

    this.usuarioService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/usuarios']).then(() => {
          this.helper.openSnackBar('Usuário inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });
  }

  buscarClientes() {
    this.buscarClientesSubscription = this.clienteService.getSelect()
      .subscribe((res: Cliente[]) => this.clientes = res);
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
