export class Diferencial {

    id: any;
    icone: string = '';
    nome: string = '';
    descricao: string = '';
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}