export class ContatoGeral {

    id: any;
    telefone: string = '';
    whatsApp: string = '';
    email: string = '';
    linkFacebook: string = '';
    linkInstagram: string = '';
    uf: string = '';
    cidade: string = '';
    rua: string = '';
    numeroEndereco: string = '';
    bairro: string = '';
    cep: string = '';
    atendimento: string = '';
    [x: string]: any;

    constructor(obj?) { 
        Object.assign(this);
    }
}