import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { CadastroGeral } from 'src/app/models/cadastro-geral.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoService {

  constructor(
    private api: ApiService
  ) { }

  get(): Observable<any> {
    return this.api.get('/cadastroGeral/buscar');
  }

  patch(cadastroGeral: CadastroGeral): Observable<any> {
    return this.api.post('/cadastroGeral/alterar', cadastroGeral);
  }

  postImage(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
