export class TipoDocumento {
    
    id: number;
    nome: string = '';
    descricao: string = '';
    idGrupoTipoDoc: number;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}