import { Component, OnInit, Inject } from '@angular/core';
import { TipoDocumento } from 'src/app/models/tipo-documento.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TipoDocumentoService } from 'src/app/services/class/tipo-documento.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-modal-child-edit',
  templateUrl: './modal-child-edit.component.html',
  styleUrls: ['./modal-child-edit.component.scss']
})
export class ModalChildEditComponent implements OnInit {

  tipoDocumento: TipoDocumento = new TipoDocumento();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TipoDocumento,
    public dialogRef: MatDialogRef<ModalChildEditComponent>,
    public helper: HelperService,
    public loadingService: LoadingService,
    public tipoDocumentoService: TipoDocumentoService
  ) { }

  ngOnInit() {
    this.loadingService.present('Buscando...');
    this.tipoDocumento = this.data;
    setTimeout(() => {
      this.buscarTipoDocumento(this.data.id);
    }, 280)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos');
      return;
    }

    this.dialogRef.close(this.tipoDocumento);
  }

  buscarTipoDocumento(id: number) {
    this.tipoDocumentoService.getById(id).subscribe((res: TipoDocumento) => {
      this.tipoDocumento.nome = res.nome;
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

}
