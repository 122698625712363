import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HelperService } from 'src/app/services/helper.service';
import { Cliente } from 'src/app/models/cliente.model';
import { ClientesApi } from 'src/app/services/class/cliente.service';
import { Subscription } from 'rxjs';
import { TipoDocumento } from 'src/app/models/tipo-documento.model';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DocumentoService } from 'src/app/services/class/documento.service';

@Component({
  selector: 'app-documentos-criar',
  templateUrl: './documentos-criar.component.html',
  styleUrls: ['./documentos-criar.component.scss']
})
export class DocumentosCriarComponent implements OnInit {

  date: string = '';

  tipoDocumento: TipoDocumento = new TipoDocumento();

  cliente: Cliente;
  clientes: Cliente[] = [];
  buscarClientesSubscription: Subscription;

  searchClientes: string = '';

  constructor(
    public dialogRef: MatDialogRef<DocumentosCriarComponent>,
    public helper: HelperService,
    public documentoService: DocumentoService,
    public router: Router
  ) {
    const startDate = this.helper.moment().startOf('month').format('MM/YYYY');
    this.date = startDate;
  }

  ngOnInit() {
    this.buscarClientes();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this.buscarClientesSubscription.unsubscribe();
  }

  buscarClientes() {
    this.buscarClientesSubscription = this.documentoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: ClientesApi) => this.clientes = res.clientes);
  }

  onChangeCliente(e: Cliente) {
    this.tipoDocumento.idGrupoTipoDoc = e.grupoTipoDoc.id;
    this.tipoDocumento.idCliente = e.id;
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.tipoDocumento.mesAno = this.helper.moment(this.date, 'MM/YYYY').format();

    this.dialogRef.close(this.tipoDocumento);
  }

}
