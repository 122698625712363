import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { ContatoService } from './../../services/class/contato.service';
import { ContatoGeral } from './../../models/contato-geral.model';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  contatoGeral: ContatoGeral = new ContatoGeral();
  buscarContatoGeralSubscription: Subscription;

  constructor(
    public contatoService: ContatoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.buscarContatoGeral();
  }

  ngOnDestroy() {
    this.buscarContatoGeralSubscription.unsubscribe();
  }

  alterarContatoGeral(f: NgForm) {
    if(f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando cadastro geral...');

    this.contatoService.alterarGeral(this.contatoGeral)
      .subscribe((res: any) => {
        this.helper.openSnackBar('Contato geral alterado com sucesso.');
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());

  }

  buscarContatoGeral() {
    this.buscarContatoGeralSubscription = this.contatoService.buscarGeral()
      .subscribe((res: ContatoGeral) => {
        this.contatoGeral = res;
      })
  }

}
