import { Diferencial } from 'src/app/models/diferencial.model';
import { DiferencialService } from 'src/app/services/class/diferencial.service';
// default
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-diferenciais-editar',
  templateUrl: './diferenciais-editar.component.html',
  styleUrls: ['./diferenciais-editar.component.scss']
})
export class DiferenciaisEditarComponent implements OnInit {

  data: Diferencial = new Diferencial();
  buscarSubscription: Subscription;

  constructor(
    public diferencialService: DiferencialService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscar(id: number) {
    this.buscarSubscription = this.diferencialService.getById(id)
      .subscribe((res: Diferencial) => this.data = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');

    this.diferencialService.patch(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/diferenciais']).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
