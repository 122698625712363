import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Injectable } from '@angular/core';
import { Servico } from 'src/app/models/servico.model';

@Injectable({
  providedIn: 'root'
})
export class ServicoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<ServicosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/servico/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/servico/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/servico/buscar?id=${id}`);
  }

  post(servico: Servico): Observable<any> {
    return this.api.post('/servico/inserir', servico);
  }

  patch(servico: Servico): Observable<any> {
    return this.api.post('/servico/alterar', servico);
  }

  delete(servico: Servico): Observable<any> {
    return this.api.post('/servico/deletar', servico);
  }

  deleteSelected(servicos: Servico[]): Observable<any> {
    return this.api.post('/servico/deletarLista', servicos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

}

export interface ServicosApi {
  servicos: Servico[];
  numeroPaginas: number;
}