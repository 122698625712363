export class Banner {
    id: any;
    titulo: string = '';
    descricao: string = '';
    link: string = '';
    imagem: string = '';
    ordem: number = 99;
    situacao: string = 'A';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}